import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("用户名"),
    dataIndex: "username",
    sorter: true,
  },
  {
    title: T("名称"),
    dataIndex: "name",
    sorter: true,
  },
  {
    title: T("角色"),
    dataIndex: "role",
    customRender: (value, item) => {
      return item.role_items.map((item) => item.name).join(", ");
    },
  },
  {
    title: T("状态"),
    dataIndex: "is_active",
    scopedSlots: { customRender: "is_active" },
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "256px",
  },
];
